import store from '@/store/store';
import {ColumnInterface} from '@/layouts/back-office/elements/table/interfaces/ColumnInterface';
import {OrderBy} from '@/layouts/back-office/elements/table/interfaces/OrderBy';

export const getEntityId = () => store.getters.user.entity.id;

export const orderBySettings = (columns: ColumnInterface[]): OrderBy[] => {
  return columns
    .filter((element: ColumnInterface) => element.orderBy && element.orderBy.default)
    .sort((a: ColumnInterface, b: ColumnInterface) => {
      if (!a.orderBy || !b.orderBy) {
        return 0;
      }
      if (!a.orderBy.order) {
        return 1;
      }
      if (!b.orderBy.order) {
        return -1;
      }
      return a.orderBy.order - b.orderBy.order;
      })
    .map((element) => element.orderBy?.settings) as OrderBy[];
};

