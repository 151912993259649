













import {Component, Prop, Vue} from 'vue-property-decorator';
import HeaderColumn from '@/layouts/back-office/elements/table/components/header/HeaderColumn.vue';
import {ColumnInterface} from '@/layouts/back-office/elements/table/interfaces/ColumnInterface';
import {OrderBy, OrderByColumnSettings} from '@/layouts/back-office/elements/table/interfaces/OrderBy';

@Component({
  components: {
    HeaderColumn,
  },
})
export default class HeaderTitles extends Vue {

  @Prop({type: Array, required: true}) private columns!: ColumnInterface[];
  @Prop({type: Object, required: true}) private queryVariables!: { 'orderBy': any };
  @Prop({type: Boolean, required: true}) private hasActionsColumn!: boolean;


  private inOrderBy(orderBy: OrderByColumnSettings | null) {
    if (!orderBy) {
      return null;
    }
    const settings = orderBy.settings;

    const orderByVariables = this.queryVariables.orderBy;

    if (!orderByVariables || orderByVariables && orderByVariables.length === 0) {
       return null;
    }
    const filtered = orderByVariables.filter((element: OrderBy) => element.field === settings.field);
    return filtered ? filtered[0] : null;
  }

  private changeOrderBy(orderBy: OrderBy[]) {
    this.$emit('changeOrderBy', orderBy);
  }

}
