













import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class FilterInspectionType extends Vue {
  private static readonly KEY = 'inspectionTypes';
  private static readonly ALL = 'all';

  @Prop({type: Number, default: 500}) protected timeout!: number;

  private selected: string|number = FilterInspectionType.ALL;
  private types: Array<{id: string, name: string}>|null = null;

  private mounted() {
    this.$nextTick(() => {
        this.fetchInspectionTypes().then(() => this.$forceUpdate());
    });
  }

  private async fetchInspectionTypes() {
    await this.$apollo.query({
      query:  require('@/graphql/queries/inspection-type/InspectionTypes.gql'),
      variables: {},
    }).then(
      (result): void => {
        if (result.data && result.data.paginateInspectionTypes) {
          this.types = [
            {id: FilterInspectionType.ALL, name: this.$it('tb.inspection-type.all-types', 'All types')},
            ...result.data.paginateInspectionTypes.data];
        }
      });
  }

  @Watch('selected')
  private watchSelected(): void {
    const newFilter = this.selected === FilterInspectionType.ALL ? null : this.selected;
    this.$emit('updateFilter', {newFilters: newFilter, dataKey: FilterInspectionType.KEY});
  }
}
