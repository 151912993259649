import i18n from '@/store/i18n';
import {ColumnInterface} from '@/layouts/back-office/elements/table/interfaces/ColumnInterface';
import {
  EntityFlowSort,
  SortOrder,
} from '@/types/auction';

export const templateColumns: ColumnInterface[] = [
  {
    title: i18n.it('global.group', 'Type') as string,
    key: 'group.name',
    slot: 'group',
    orderBy: {
      default: true,
      order: 2,
      settings: {
        field: EntityFlowSort.GROUP,
        order: SortOrder.ASC,
      },
    },
  },
  {
    title: i18n.it('global.inspection-type', 'Inspection type') as string,
    key: 'inspectionType.name',
    orderBy: {
      default: true,
      order: 1,
      settings: {
        field: EntityFlowSort.INSPECTION_TYPE,
        order: SortOrder.ASC,
      },
    },
  },
  {
    title: i18n.it('tb.list.last-published-at', 'Last published at') as string,
    key: 'publishedAt',
    slot: 'date',
    orderBy: {
      default: false,
      settings: {
        field: EntityFlowSort.PUBLISHED_AT,
        order: SortOrder.DESC,
      },
    },
  },
];

