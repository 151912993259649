import {SmartQuery} from 'vue-apollo/types/vue-apollo';

export const fetchMore = async (query: SmartQuery<any>, dataKey: string, newVariables: object) => {
  return await query.fetchMore({
    variables: newVariables,
    updateQuery: (prev: any, {fetchMoreResult}: any) => {
      const paginate = fetchMoreResult[dataKey];
      paginate.data = Object.freeze([...prev[dataKey].data, ...paginate.data]);
      return {
        [dataKey]: paginate,
      };
    },
  });
};
