const range = (min: number, max: number) => [...Array(max - min + 1).keys()].map((i) => i + min);

export const firstSection = (lastPage: number, pagesPerSection: number): number[] => {
  const lastPageInSection = lastPage < pagesPerSection ? lastPage : pagesPerSection;
  return range(1, lastPageInSection);
};

export const lastSection = (currentPage: number, lastPage: number, pagesPerSection: number): number[] => {
  if (lastPage <= pagesPerSection) {
    return [];
  }
  if (lastPage === pagesPerSection + 1) {
    return [pagesPerSection + 1];
  }
  if (lastPage <= (pagesPerSection * 2)) {
    return range(pagesPerSection + 1, lastPage);
  }
  const firstPageInSection = firstPageInLastSection(currentPage, lastPage, pagesPerSection);
  return range(firstPageInSection, lastPage);
};

const firstPageInLastSection = (currentPage: number, lastPage: number, pagesPerSection: number): number => {
  const thirdLastPage = lastPage - (pagesPerSection - 1);
  return currentPage < thirdLastPage ? thirdLastPage : currentPage;
};

export const needsEllipsisAfterFirstSection =
  (currentPage: number, lastPage: number, pagesPerSection: number): boolean => {
  if (currentPage === pagesPerSection + 1) {
    return false;
  }
  return lastPage > (pagesPerSection * 2);
};

export const needsEllipsisBeforeLastSection =
  (currentPage: number, lastPage: number, pagesPerSection: number): boolean => {
  if (currentPage <= pagesPerSection) {
    return false;
  }
  const firstPage = firstPageInLastSection(currentPage, lastPage, pagesPerSection);
  if (currentPage === (firstPage - 1)) {
    return false;
  }
  return currentPage < firstPage;
};

export const needsInBetweenSection =
  (currentPage: number, lastPage: number, pagesPerSection: number): boolean => {
  if (currentPage <= pagesPerSection) {
    return false;
  }
  const firstPage = firstPageInLastSection(currentPage, lastPage, pagesPerSection);
  if (currentPage >= firstPage) {
    return false;
  }
  return currentPage - 1 !== firstPage;
};
