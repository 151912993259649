































































import {Component, Vue} from 'vue-property-decorator';
import {templateColumns} from '@/components/template-builder/overview/TemplateColumns';
import TableContainer from '@/layouts/back-office/elements/table/components/TableContainer.vue';
import DateComponent from '@/layouts/back-office/elements/table/components/DateComponent.vue';
import ResizableContainer from '@/layouts/back-office/elements/resize-container/ResizableContainer.vue';
import ManageColumnsForm from '@/layouts/back-office/elements/table/components/toolbar/manage-columns/ManageColumnsForm.vue';
import UIButton from '@/layouts/back-office/ui-kit/buttons/UIButton.vue';

import {
  PaginateEntityFlows,
  MyEntityFlowsOrderByOrderByClause,
} from '@/types/auction';
import {orderBySettings} from '@/layouts/back-office/elements/table/helpers/QueryVariablesHelper';
import TablePagination from '@/layouts/back-office/elements/table/components/pagination/TablePagination.vue';
import {TableDataInterface} from '@/layouts/back-office/elements/table/interfaces/TableDataInterface';
import HeaderSorting from '@/components/template-builder/overview/HeaderSorting.vue';
import {isCurrentRoute, newFilterQuery } from '@/plugins/filtering/FilterRouteHelper';
import {Meta} from '@sophosoft/vue-meta-decorator';
import {UpdateFilter} from '@/components/template-builder/overview/interfaces/UpdateFilterInterface';

@Component({
  components: {
    HeaderSorting,
    TablePagination,
    UIButton,
    ResizableContainer,
    DateComponent,
    TableContainer,
    ManageColumnsForm,
  },
})
export default class ListTemplates extends Vue {
  private readonly dataKey = `myEntityFlows`;
  private columns = templateColumns;

  private displaySidebar: boolean = false;
  private widthSidebar: string = '15%';
  private fixedSidebar: boolean = false;

  private queryVariables = {};

  private mounted() {
    this.$nextTick(() => {
      this.currentQueryVariables();
      const orderBy = orderBySettings(this.columns) as MyEntityFlowsOrderByOrderByClause[];
      this.changeOrderBy(orderBy);
    });
  }

  private currentQueryVariables() {
    this.queryVariables = this.$route.query;
  }

  /**
   * Method to change the orderBy settings
   * @todo: Support multiple fields
   */
  private changeOrderBy(settings: MyEntityFlowsOrderByOrderByClause[]) {
    this.queryVariables = {...this.queryVariables, orderBy: settings};
  }

  private updateFilter(args: UpdateFilter) {
    const newQuery = newFilterQuery(this.queryVariables, args);
    if (isCurrentRoute(this.queryVariables, newQuery)) {
      return;
    }
    this.queryVariables = newQuery;
  }

  private resolveData = (result: PaginateEntityFlows): TableDataInterface => {
    const paginate = result[this.dataKey];
    if (!paginate) {
      throw new Error('No data');
    }
    return {
      data: paginate.data,
      pagination: paginate.paginatorInfo,
    };
  }

  @Meta
  private getMetaInfo() {
    return {
      title: `${this.$it('global.overview', 'Overview')} | ${this.$it('tb.title', 'Template Builder')}`,
    };
  }
}
