



















import {Component, Prop, Vue} from 'vue-property-decorator';
import TablePagination from '@/layouts/back-office/elements/table/components/pagination/TablePagination.vue';
import InfinitePagination from '@/layouts/back-office/elements/table/components/pagination/InfinitePagination.vue';
import {PaginationInterface} from '@/layouts/back-office/elements/table/interfaces/PaginationInterface';
import {StateChanger} from 'vue-infinite-loading';

@Component({
  components: {
    InfinitePagination,
    TablePagination,
  },
})
export default class PaginationTemplates extends Vue {
  @Prop({type: String, required: false, default: 'infinite'}) private paginationType!: 'infinite' | 'table';
  @Prop(Object) private pagination!: PaginationInterface;
  @Prop({type: Boolean, required: false, default: false}) private isLoading!: boolean;

  private updateFilter(args: {newFilters: any | null, dataKey: string}) {
    this.$emit('updateFilter', args);
  }

  private infiniteHandler(stateChanger: StateChanger) {
    this.$emit('loadMore', stateChanger, this.pagination);
  }

}
