












import {Component, Prop, Vue} from 'vue-property-decorator';
import {ColumnInterface} from '@/layouts/back-office/elements/table/interfaces/ColumnInterface';
import FilterInspectionType from '@/components/template-builder/overview/FilterInspectionType.vue';
import {UpdateFilter} from '@/components/template-builder/overview/interfaces/UpdateFilterInterface';

@Component({
  components: {
    FilterInspectionType,
  },
})
export default class HeaderSorting extends Vue {
  @Prop({type: Array, required: true}) private columns!: ColumnInterface[];
  @Prop(Object) private queryVariables?: object;

  private updateFilter(args: UpdateFilter): void {
    this.$emit('updateFilter', args);
  }
}
