













import {Component, Prop, Vue} from 'vue-property-decorator';
import {ColumnInterface} from '@/layouts/back-office/elements/table/interfaces/ColumnInterface';

@Component({
  components: {
  },
})
export default class ManageColumnField extends Vue {
  @Prop({type: Object, required: true}) private column!: ColumnInterface;
  @Prop({type: Boolean, default: true}) private inSelection!: boolean;

  private isSelected: boolean = this.inSelection;

  private changeSelectedColumn() {
    this.$emit('changeSelectedColumn', {key: this.column.key, value: this.isSelected});
  }

}

