












import {Component, Prop, Vue} from 'vue-property-decorator';
import ManageColumnField from '@/layouts/back-office/elements/table/components/toolbar/manage-columns/ManageColumnField.vue';
import {ColumnInterface} from '@/layouts/back-office/elements/table/interfaces/ColumnInterface';

@Component({
  components: {
    ManageColumnField,
  },
})
export default class ManageColumnsForm extends Vue {
  @Prop({type: Array, required: true}) private originalColumns!: ColumnInterface[];
  @Prop({type: Array, required: true}) private selectedColumns!: ColumnInterface[];

  private inSelection(column: ColumnInterface): boolean {
    return this.selectedColumns.some((element: ColumnInterface) => element.key === column.key);
  }

  private changeSelectedColumn(args: {key: string, value: boolean}) {
    const newSelection = this.changeSelectedColumns(args);
    this.$emit('changeSelectionColumns', newSelection);
  }

  private changeSelectedColumns(columnToChange: {key: string, value: boolean}): ColumnInterface[] {
    if (columnToChange.value) {
      return this.addColumnToSelection(columnToChange);
    }
    return this.removeColumnFromSelection(columnToChange);
  }

  private addColumnToSelection(columnToAdd: {key: string, value: boolean}): ColumnInterface[] {
    const column: ColumnInterface|undefined = this.originalColumns.find(
      (element: ColumnInterface) => element.key === columnToAdd.key);
    if (!column) {
      return this.selectedColumns;
    }
    this.selectedColumns.push(column);
    return this.selectedColumns;
  }

  private removeColumnFromSelection(columnToDelete: {key: string, value: boolean}): ColumnInterface[] {

    const newSelection = this.selectedColumns.slice();
    return newSelection.filter(
        (element: ColumnInterface) => {
          return element.key !== columnToDelete.key;
    });
  }

}
