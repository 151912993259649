











import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class TotalPerPage extends Vue {
  @Prop({type: Number, required: true}) private items!: number;
  @Prop({type: Number, required: true}) private currentCount!: number;

  private totalPerPage = this.currentCount;
  private numbersToChoose = [25, 50, 100];

  private changeTotalPerPage() {
    this.$emit('changeTotalPerPage', this.totalPerPage);
  }
}
