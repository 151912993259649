/**
 * This method will fetch the value of the item by key.
 * The key can be nested like: lorem.ipsum[0].delor
 */
export const resolveCellData = (key: string, item: any): any => {
  const positionIndex = key.indexOf('[');
  const positionPeriod = key.indexOf('.');

  if (positionIndex === -1 && positionPeriod === -1) {
    return item ? item[key] : null;
  }

  if (positionIndex === -1) {
    return resolveNestedKey(key, item);
  }

  if (positionPeriod === -1) {
    return resolveNestedIndex(key, item);
  }

  if (positionPeriod < positionIndex) {
    return resolveNestedKey(key, item);
  }
  return resolveNestedIndex(key, item);
};

const resolveNestedKey = (key: string, item: any): any => {
  const [parentKey] = key.split('.', 1);
  const newKey = key.replace(`${parentKey}.`, '');

  const parentItem = item[parentKey];

  return resolveCellData(newKey, parentItem);
};

const resolveNestedIndex = (key: string, item: any): any => {

  const positionStart = key.indexOf('[');
  const positionEnd = key.indexOf(']');

  const property = key.slice(0, positionStart);
  const itemProperty = item[property];

  if (itemProperty.length === 0) {
    return null;
  }

  const index = key.slice(positionStart + 1, positionEnd);
  const newKey = key.slice(positionEnd + 1);

  const newItem = itemProperty[index];

  if (newKey.indexOf('.') !== 0) {
    return resolveCellData(newKey, newItem);
  }

  const keyWithoutBeginningPeriod = newKey.slice(1);
  return resolveCellData(keyWithoutBeginningPeriod, newItem);
};
