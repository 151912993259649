






















import {Component, Prop, Vue} from 'vue-property-decorator';
import {StateChanger} from 'vue-infinite-loading';
import {PaginationInterface} from '@/layouts/back-office/elements/table/interfaces/PaginationInterface';
import LoadingEllipsis from '@/layouts/back-office/ui-kit/alert/LoadingEllipsis.vue';
import Alert from '@/layouts/back-office/ui-kit/alert/Alert.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

@Component({
  components: {
    Alert,
    LoadingEllipsis,
    Spinner,
  },
})
export default class InfinitePagination extends Vue {
  @Prop(Object) private pagination!: PaginationInterface;
  @Prop({type: Boolean, required: false, default: false}) private isLoading!: boolean;

  private infiniteHandler(stateChanger: StateChanger) {
    this.$emit('loadMore', stateChanger, this.pagination);
  }
}
