



































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class UIButton extends Vue {
  @Prop({type: String, required: true, default: 'contained'}) private variant!: 'contained' | 'outlined' | 'text';
  @Prop({type: String, required: true, default: 'primary'}) private color!: 'primary' | 'secondary' | 'success' | 'warning' | 'info' | 'error' | 'light' | 'dark';
  @Prop({type: String, required: false, default: 'medium'}) private size!: 'small' | 'medium' | 'large';
  @Prop({type: String, required: false, default: 'none'}) private textTransform!: 'uppercase' | 'none' | 'capitalize';
  @Prop({type: String, required: false, default: 'default'}) private shape!: 'round';
  @Prop({type: String, required: false, default: ''}) private title!: string;
  @Prop({type: Boolean, required: false, default: false}) private disabled!: boolean;
  @Prop({type: String, required: false}) private tooltip!: string;
  @Prop({type: String, required: false}) private tooltipPosition!: 'up' | 'down' | 'left' | 'up-left' | 'up-right' | 'down-left' | 'down-right';

  private get buttonClasses() {

    const isOutlined = this.variant === 'outlined';
    const isText = this.variant === 'text';

    if (isText) {
      return {
        ...this.defaultClasses(),
        'variant-text': isText,
        'color-primary': this.color === 'primary',
        'color-secondary': this.color === 'secondary',
        'color-success': this.color === 'success',
        'color-warning': this.color === 'warning',
        'color-info': this.color === 'info',
        'color-error': this.color === 'error',
        'color-light': this.color === 'light',
        'color-dark': this.color === 'dark',
      };
    }

    return {
      ...this.defaultClasses(),
      [`btn-${isOutlined ? 'outline-' : ''}${this.color}`]: !isText,
    };
  }

  private defaultClasses() {
    return {
      'btn-small': this.size === 'small',
      'btn-large': this.size === 'large',
      'disabled': this.disabled,
    };
  }


  private get messageClasses() {
    return {
      'text-transform-none': this.textTransform === 'none',
      'text-transform-capitalize': this.textTransform === 'capitalize',
      'text-transform-uppercase': this.textTransform === 'uppercase',
    };
  }
}
