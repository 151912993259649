



























































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {ColumnInterface} from '@/layouts/back-office/elements/table/interfaces/ColumnInterface';
import {resolveCellData} from '@/layouts/back-office/elements/table/helpers/ResolveCellData';
import UITable from '@/layouts/back-office/ui-kit/table/UITable.vue';
import HeaderTitles from '@/layouts/back-office/elements/table/components/header/HeaderTitles.vue';
import {OrderBy} from '@/layouts/back-office/elements/table/interfaces/OrderBy';
import Alert from '@/layouts/back-office/ui-kit/alert/Alert.vue';
import LoadingEllipsis from '@/layouts/back-office/ui-kit/alert/LoadingEllipsis.vue';
import {StateChanger} from 'vue-infinite-loading';
import { TableDataInterface } from '../interfaces/TableDataInterface';
import {SmartQuery} from 'vue-apollo/types/vue-apollo';
import {PaginationInterface} from '@/layouts/back-office/elements/table/interfaces/PaginationInterface';
import {fetchMore} from '@/layouts/back-office/elements/table/helpers/InfiniteLoadingHelper';
import PaginationTemplates from '@/layouts/back-office/elements/table/components/pagination/PaginationTemplates.vue';

@Component({
  components: {
    PaginationTemplates,
    LoadingEllipsis,
    Alert,
    HeaderTitles,
    UITable,
  },
})
export default class TableContainer extends Vue {
  @Prop({type: String, default: 'cache-first'}) protected fetchPolicy!: string;
  @Prop({type: String, required: true}) private clientId!: string;
  @Prop({type: Array, required: true}) private columns!: ColumnInterface[];
  @Prop({type: String, required: true}) private dataKey!: string;
  @Prop({type: String, required: false, default: 'apolloQuery' }) private reference!: string;
  @Prop({type: String, required: false}) private title!: string;
  @Prop([Function, Object]) private query!: () => void | object;
  @Prop([Function, Object]) private resolveData!: (result: any) => TableDataInterface;
  @Prop(Object) private queryVariables?: object;
  @Prop({type: String, required: false, default: 'infinite'}) private paginationType!: 'infinite' | 'table';

  private resolveCellValue = resolveCellData;

  private selectedColumns = this.columns.slice();

  private hasSlot(name: string) {
    return !!this.$slots[name] || !!this.$scopedSlots[name];
  }

  private changeOrderBy(orderBy: OrderBy[]) {
    this.$emit('changeOrderBy', orderBy);
  }

  private updateFilter(args: {newFilters: any | null, dataKey: string}) {
    this.$emit('updateFilter', args);
  }

  private get queryResult(): SmartQuery<any> | null {
    if (this.$refs[this.reference]) {
      return (this.$refs[this.reference] as any).getApolloQuery();
    }
    return null;
  }

  private async infiniteHandler(stateChanger: StateChanger, pagination: PaginationInterface) {

    const query = this.queryResult;
    if (!query) {
      return;
    }

    const newVariables = {...this.queryVariables, page: pagination.currentPage + 1};
    const result = await fetchMore(query, this.dataKey, newVariables);

    const data = result.data[this.dataKey];

    if (!data.paginatorInfo.hasMorePages) {
      return stateChanger.complete();
    }
    return stateChanger.loaded();
  }

}
