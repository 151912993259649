
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PaginatorInfo"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PaginatorInfo"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"currentPage"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"firstItem"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"hasMorePages"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lastItem"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lastPage"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"perPage"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"total"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":133}};
    doc.loc.source = {"body":"fragment PaginatorInfo on PaginatorInfo {\n  count\n  currentPage\n  firstItem\n  hasMorePages\n  lastItem\n  lastPage\n  perPage\n  total\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
