






















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class UITable extends Vue {

  @Prop({type: Boolean, required: false, default: true}) private fullWidth!: boolean;
  @Prop({type: String, required: false, default: 'striped'}) private type!: 'striped';
}
