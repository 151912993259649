var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{staticClass:"page"},[_c('ResizableContainer',{attrs:{"displaySidebar":_vm.displaySidebar,"widthSidebar":_vm.widthSidebar,"fixedSidebar":_vm.fixedSidebar},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('table-container',{attrs:{"client-id":"auctionGraphqlClient","columns":_vm.columns,"title":String(_vm.$it('tb.title', 'Template Builder')),"reference":"entityFlows","query":require('@/graphql/queries/flows/PaginateEntityFlows.gql'),"resolveData":_vm.resolveData,"queryVariables":_vm.queryVariables,"dataKey":_vm.dataKey,"paginationType":"infinite"},on:{"changeOrderBy":_vm.changeOrderBy,"updateFilter":_vm.updateFilter},scopedSlots:_vm._u([{key:"headerSorting",fn:function(ref){
var columns = ref.columns;
var isHidden = ref.isHidden;
return [(!isHidden)?[_c('HeaderSorting',{attrs:{"columns":columns,"queryVariables":_vm.queryVariables},on:{"updateFilter":_vm.updateFilter}})]:_vm._e()]}},{key:"actionBar",fn:function(){return undefined},proxy:true},{key:"group",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"ml-2"},[_c('router-link',{attrs:{"to":{ name: 'tb-detail', params: { id: item.id }}}},[_vm._v(" "+_vm._s(value ? value : _vm.$it('tb.no-group-specified', 'No group specified'))+" ")])],1)]}},{key:"date",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('DateComponent',{attrs:{"type":"date","value":value}})]}},{key:"rowActions",fn:function(ref){
var item = ref.item;
return [_c('UIButton',{attrs:{"variant":"text","color":"primary","size":"small","tooltip":((String(_vm.$it('tb.copy_flow', 'Copy Flow'))) + " '" + (item.group ? item.group.name :
                      _vm.$it('tb.no-group-specified', 'No group specified')) + "'"),"tooltipPosition":"left"}},[_c('i',{staticClass:"fa fa-clone",attrs:{"aria-hidden":"true"}})])]}}])})]},proxy:true},{key:"sidebar",fn:function(){return undefined},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }