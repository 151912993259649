






import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class DateComponent extends Vue {
  @Prop({type: String, required: true, default: 'date'}) private type!: string;
  @Prop({type: String, default: ''}) private value!: string;
}
