


































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {PaginationInterface} from '@/layouts/back-office/elements/table/interfaces/PaginationInterface';
import TotalPerPage from '@/layouts/back-office/elements/table/components/pagination/TotalPerPage.vue';
import UIButton from '@/layouts/back-office/ui-kit/buttons/UIButton.vue';
import {
  firstSection,
  lastSection,
  needsEllipsisAfterFirstSection,
  needsEllipsisBeforeLastSection,
  needsInBetweenSection,
} from '@/layouts/back-office/elements/table/helpers/PaginationHelper';

@Component({
  components: {UIButton, TotalPerPage},
})
export default class TablePagination extends Vue {

  @Prop(Object) private pagination!: PaginationInterface;
  private pagesPerSection = 3;

  private firstSection = firstSection(
    this.pagination.lastPage,
    this.pagesPerSection,
  );

  private lastSection = lastSection(
    this.pagination.currentPage,
    this.pagination.lastPage,
    this.pagesPerSection,
  );

  private needsEllipsisAfterFirstSection = needsEllipsisAfterFirstSection(
    this.pagination.currentPage,
    this.pagination.lastPage,
    this.pagesPerSection,
  );

  private needsEllipsisBeforeLastSection = needsEllipsisBeforeLastSection(
    this.pagination.currentPage,
    this.pagination.lastPage,
    this.pagesPerSection,
  );

  private needsInBetweenSection = needsInBetweenSection(
    this.pagination.currentPage,
    this.pagination.lastPage,
    this.pagesPerSection,
  );

  private nextPage() {
    this.changePage(this.pagination.currentPage + 1);
  }

  private previousPage() {
    this.changePage(this.pagination.currentPage - 1);
  }

  private changePage(page: number) {
    this.$emit('updateFilter', {newFilters: page, dataKey: 'page'});
  }
  private changeTotalPerPage(total: number) {
    this.$emit('updateFilter', {newFilters: total, dataKey: 'first'});
  }
}
