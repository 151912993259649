export const newFilterQuery = (currentQuery: any, args: {newFilters: any | null, dataKey: string}) => {
  const routeQuery = {...currentQuery};
  const isToBeRemoved = !args.newFilters && routeQuery.hasOwnProperty(args.dataKey);

  if (isToBeRemoved) {
    delete routeQuery[args.dataKey];
  }

  return isToBeRemoved || !args.newFilters ?
    {...routeQuery} : {...routeQuery, [args.dataKey]: args.newFilters };
};

export const isCurrentRoute = (currentQuery: any, newQuery: any): boolean => {
  if (Object.keys(newQuery).length !== Object.keys(currentQuery).length) {
    return false;
  }
  return Object
    .entries(newQuery)
    .every(([key, value]) => {
      if (!currentQuery.hasOwnProperty(key)) {
        return false;
      }
      const filter = currentQuery[key];
      if (!(filter instanceof Array)) {
        return isCurrentFilter(value, filter);
      }

      return filter.every((element: any) => isCurrentFilter(value, element));
    });
};

const isCurrentFilter = (newValue: any, currentFilter: any) => {
  if (!(newValue instanceof Array)) {
    return currentFilter === newValue;
  }
  return newValue.includes(currentFilter);
};
