import { render, staticRenderFns } from "./InfinitePagination.vue?vue&type=template&id=75b5516e&scoped=true&"
import script from "./InfinitePagination.vue?vue&type=script&lang=ts&"
export * from "./InfinitePagination.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75b5516e",
  null
  
)

export default component.exports