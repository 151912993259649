















import {Component, Prop, Vue} from 'vue-property-decorator';
import {ColumnInterface} from '@/layouts/back-office/elements/table/interfaces/ColumnInterface';
import {OrderBy} from '@/layouts/back-office/elements/table/interfaces/OrderBy';
import { SortOrder } from '@/types/auction';

@Component({
  components: {},
})
export default class HeaderColumn extends Vue {
  @Prop({type: Object, required: true}) private column!: ColumnInterface;
  @Prop(Object) private orderBy?: OrderBy | null;

  private get titleClass() {
    return {
      active: this.orderBy,
      inactive: !this.orderBy,
    };
  }

  private get iconClass() {
    if (!this.column.orderBy) {
      return;
    }
    const currentDirection = this.currentDirection(this.column.orderBy.settings);
    return {
      'fa-sort-amount-up': currentDirection === SortOrder.ASC,
      'fa-sort-amount-down': currentDirection === SortOrder.DESC,
    };
  }

  private get title(): string {
    if (!this.column.orderBy) {
      return '';
    }
    const nextDirection = this.nextDirection(this.column.orderBy.settings);
    if (nextDirection === SortOrder.ASC) {
      return this.$it('global.click-to-ascend', 'Click for ascending order') as string;
    }
    return this.$it('global.click-to-descend', 'Click for descending order') as string;
  }

  private currentDirection(columnOrderBy: OrderBy): SortOrder {
    if (!this.isCurrentSortedField(columnOrderBy.field) || !this.orderBy) {
      return columnOrderBy.order;
    }

    return this.orderBy.order;
  }

  private isCurrentSortedField(field: string): boolean {
    return !!(this.orderBy && this.orderBy.field === field);
  }

  private nextDirection(columnOrderBy: OrderBy): SortOrder {
    const direction = this.currentDirection(columnOrderBy);

    if (!this.isCurrentSortedField(columnOrderBy.field)) {
      return direction;
    }

    return direction === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
  }

  private changeSortDirection(): void {
    const columnOrderBy = this.column.orderBy;
    if (!columnOrderBy) {
      return;
    }
    const newDirection = this.nextDirection(columnOrderBy.settings);
    this.$emit('changeOrderBy', [{field: columnOrderBy.settings.field, order: newDirection}]);
  }

}
